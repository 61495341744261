<template>
    <div v-if="loaded" class="pa-5">
        <div class="btnPanel">
            <v-btn color="error" @click="$emit('close')">閉じる</v-btn>
            <v-btn color="success" @click="acceptShinsei(selectedShinsei._id)">承認</v-btn>
            <v-btn color="error" @click="declineShinsei(selectedShinsei._id)">却下</v-btn>
            <v-btn color="warning" @click="declineAndRequestShinsei(selectedShinsei._id)">再確認を要請</v-btn>
        </div>
        <!--Msg-->
        <div v-if="singleMode" class="pa-3 text-center error--text">
            このレコードの削除を要請しています。
        </div>
        <div class="d-flex py-5 justify-center">
            <!--Before-->
            <v-simple-table v-if="originalRecord && !singleMode" style="max-width: 50vw">
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <v-chip
                                    class="ma-1"
                                    :color="flag ? 'success' : 'error'"
                                    v-for="(flag, prop) in originalRecord.flags"
                                    :key="prop"
                                >
                                    {{ map.flag[prop] }} :
                                    <v-icon>
                                        {{ flag ? "mdi-check" : "mdi-close" }}
                                    </v-icon>
                                </v-chip>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                開始時間
                                <v-icon v-if="propIsDiff.recordStart" color="red" class="animate__animated animate__pulse animate__infinite">mdi-exclamation-thick</v-icon>
                            </td>
                            <td>{{ originalRecord.recordStart }}</td>
                        </tr>
                        <tr>
                            <td>
                                終了時間
                                <v-icon v-if="propIsDiff.recordEnd" color="red" class="animate__animated animate__pulse animate__infinite">mdi-exclamation-thick</v-icon>
                            </td>
                            <td>
                                {{ originalRecord.recordEnd }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                稼働時間
                                <v-icon v-if="propIsDiff.totalTime" color="red" class="animate__animated animate__pulse animate__infinite">mdi-exclamation-thick</v-icon>
                            </td>
                            <td>
                                <div >
                                    {{$gf.convertTime(originalRecord.totalTime)}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                開始現場
                                <v-icon v-if="propIsDiff.recordGenbaStart" color="red" class="animate__animated animate__pulse animate__infinite">mdi-exclamation-thick</v-icon>
                            </td>
                            <td>
                                <div>
                                    {{map.genba[originalRecord.recordGenbaStart]}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                終了現場
                                <v-icon v-if="propIsDiff.recordGenbaEnd" color="red" class="animate__animated animate__pulse animate__infinite">mdi-exclamation-thick</v-icon>
                            </td>
                            <td>
                                <div>
                                    {{map.genba[originalRecord.recordGenbaEnd]}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                工種
                                <v-icon v-if="propIsDiff.recordKoushu" color="red" class="animate__animated animate__pulse animate__infinite">mdi-exclamation-thick</v-icon>
                            </td>
                            <td>
                                <div>
                                    {{map.koushu[originalRecord.recordKoushu]}}
                                </div>
                            </td>
                        </tr>
                        <!--休憩・移動-->
                        <tr v-for="(subRecord, index) in originalRecord.subRecords" :key="index">
                            <td colspan="2">
                                <v-list dense>
                                    <v-list-item-group>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon v-text="'mdi-circle-medium'"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{$gf.convertLabel(subRecord.subRecordType)}}
                                                    <span v-if="subRecord.comparedFlag === 1">
                                                        <v-chip color="red">
                                                            削除
                                                        </v-chip>
                                                    </span>
                                                </v-list-item-title>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <tbody>
                                                            <tr>
                                                                <td>開始時間</td>
                                                                <td>{{subRecord.subRecordStart}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>終了時間</td>
                                                                <td>{{subRecord.subRecordEnd}}</td>
                                                            </tr>
                                                            <template v-if="$gf.convertLabel(subRecord.subRecordType) === '移動'">
                                                                <tr>
                                                                     <td>開始現場</td>
                                                                    <td>{{map.genba[subRecord.subRecordStartGenba]}}</td>
                                                                </tr>
                                                                <tr>
                                                                     <td>終了現場</td>
                                                                    <td>{{map.genba[subRecord.subRecordEndGenba]}}</td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>       
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <!---->
            <div v-if="originalRecord && !singleMode" class="d-flex align-center">
                <v-icon x-large>
                    mdi-arrow-right-bold
                </v-icon>
            </div>
            <!---->
            <!--After-->
            <v-simple-table style="max-width: 50vw">
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td colspan="2">
                                <v-chip
                                    class="ma-1"
                                    :color="flag ? 'success' : 'error'"
                                    v-for="(flag, prop) in editedRecord.flags"
                                    :key="prop"
                                >
                                    {{ map.flag[prop] }} :
                                    <v-icon>
                                        {{ flag ? "mdi-check" : "mdi-close" }}
                                    </v-icon>
                                </v-chip>
                            </td>
                        </tr>
                        <tr>
                            <td>開始時間</td>
                            <td>{{ editedRecord.recordStart }}</td>
                        </tr>
                        <tr>
                            <td>終了時間</td>
                            <td>{{ editedRecord.recordEnd }}</td>
                        </tr>
                        <tr>
                            <td>稼働時間</td>
                            <td>
                                <div >
                                    {{$gf.convertTime(editedRecord.totalTime)}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>開始現場</td>
                            <td>
                                
                                <div>
                                    {{map.genba[editedRecord.recordGenbaStart]}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>終了現場</td>
                            <td>
                            
                                <div>
                                    {{map.genba[editedRecord.recordGenbaEnd]}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                工種
                            </td>
                            <td>
                                <div>
                                    {{map.koushu[editedRecord.recordKoushu]}}
                                </div>
                            </td>
                        </tr>
                        <!--休憩・移動-->
                        <tr v-for="(subRecord, index) in editedRecord.subRecords" :key="index">
                            <td colspan="2">
                                <v-list dense>
                                    <v-list-item-group>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon v-text="'mdi-circle-medium'"></v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title >
                                                    {{$gf.convertLabel(subRecord.subRecordType)}}
                                                    <span v-if="!subRecord.comparedFlag && originalRecord">
                                                        <v-chip color="success">
                                                            新規
                                                        </v-chip>
                                                    </span>
                                                </v-list-item-title>
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <tbody>
                                                            <tr>
                                                                <td>開始時間</td>
                                                                <td>{{subRecord.subRecordStart}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>終了時間</td>
                                                                <td>{{subRecord.subRecordEnd}}</td>
                                                            </tr>
                                                            <template v-if="$gf.convertLabel(subRecord.subRecordType) === '移動'">
                                                                <tr>
                                                                     <td>開始現場</td>
                                                                    <td>{{map.genba[subRecord.subRecordStartGenba]}}</td>
                                                                </tr>
                                                                <tr>
                                                                     <td>終了現場</td>
                                                                    <td>{{map.genba[subRecord.subRecordEndGenba]}}</td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>       
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <!---->
        </div>
        <v-card flat>
            <v-card-title>申請理由</v-card-title>
            <v-card-text>
                {{selectedShinsei.reason}}
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)
export default {
    props: ["selectedShinsei","singleMode"],
    data: () => ({
        loaded:false,
        originalRecord: null,
        editedRecord: null,
        map: {
            genba: {},
            koushu: {},
            flag: {
                kyuuShutsu: "休出",
                yuuKyuu: "有休",
                kekKin: "欠勤",
                kouKyuu: "公休",
                daiKyuu: "代休",
                furiKae: "振替",
            },
        },
        propIsDiff:{
            
        }
    }),
    async mounted() {
        await this.getGenbaKoushuInfo()
        this.editedRecord = this.selectedShinsei.editedRecord;
        if(this.selectedShinsei.originalRecordId){
            await this.getSingleRecord(
                this.selectedShinsei.userId._id,
                this.selectedShinsei.originalRecordId
            );
            this.compareRecords(
                this.originalRecord,
                this.editedRecord
            )
        }
        this.loaded = true
    },
    methods: {
        getSingleRecord(uid, rid) {
            return new Promise(async(resolve, reject)=>{
                let response = await this.$store.dispatch("get", {
                    url: "/api/getSingleRecord?uid=" + uid + "&rid=" + rid,
                    showUI: true,
                });
                if (response.result) {
                    this.originalRecord = response.result;
                    resolve()
                }
                reject()
            })
        },
        compareRecords(ori, edited){
            for(let prop in ori){
                if(prop === 'subRecords'){
                    ori[prop].forEach(oriSub=>{
                        for(let i = 0 ; i<edited[prop].length ; i++){
                            if(oriSub.subId === edited[prop][i].subId){
                                //Check if same or edited
                                if(JSON.stringify(oriSub) === JSON.stringify(edited[prop][i])){
                                    oriSub.comparedFlag = 3
                                    edited[prop][i].comparedFlag = 3
                                    break
                                }
                                else{
                                    edited[prop][i].comparedFlag = 2
                                    break
                                }
                            }
                            oriSub.comparedFlag = 1
                        }
                    })
                }else
                    if(JSON.stringify(ori[prop])===JSON.stringify(edited[prop]))
                        this.propIsDiff[prop] = false
                    else 
                        this.propIsDiff[prop] = true
            }
        },
        getGenbaKoushuInfo(){
            return new Promise(async(resolve, reject)=>{
                let response = await this.$store.dispatch('get', {url:'/api/getGenbaKoushu', showUI:true})
                if(response.result){
                    this.genbaKoushuData = response.result
                    this.generateGenbaKoushuMap(this.genbaKoushuData)
                    resolve()
                }
            })
        },
        generateGenbaKoushuMap(genbaKoushuData){
            genbaKoushuData.genbaInfo.forEach(genba => {
                this.map.genba[genba.genbaCode] = genba.genbaName
            });
            genbaKoushuData.koushuInfo.forEach(koushu => {
                this.map.koushu[koushu.koushuCode] = koushu.koushuName
            });
            this.genbaKoushuInit = true
        },
        async acceptShinsei(selectedShinseiId){
            await this.$store.dispatch('post', {url:'/api/acceptShinsei', showUI:true, rawData:{targetId:selectedShinseiId}})
            this.$emit('refresh')
        },
        async declineAndRequestShinsei(selectedShinseiId){
            await this.$store.dispatch('post', {url:'/api/declineAndRequestShinsei', showUI:true, rawData:{targetId:selectedShinseiId}})
            this.$emit('refresh')
        },
        async declineShinsei(selectedShinseiId){
            await this.$store.dispatch('post', {url:'/api/declineShinsei', showUI:true, rawData:{targetId:selectedShinseiId}})
            this.$emit('refresh')
        }
    },
};
</script>

<style>
:root {
    --animate-duration: 3s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}
.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.animate__animated.animate__infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    50% {
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        transform: scale3d(1.5, 1.5, 1.5);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
@keyframes pulse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    50% {
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        transform: scale3d(1.5, 1.5, 1.5);
    }
    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}
.animate__pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
</style>
