var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shinseiData)?_c('div',{key:'tableKey'+_vm.tableKey},[_c('div',{staticClass:"banner pa-5 text-center text-h3 rounded my-3"},[_vm._v("申請一覧")]),_c('v-data-table',{attrs:{"height":"90vh","headers":_vm.headers,"items":_vm.shinseiData,"no-data-text":"データーがありません","item-key":"_id","footer-props":{
            showFirstLastPage: true,
            'items-per-page-text': '表示数',
            'items-per-page-options': [10, 50, -1],
        },"show-select":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.created.split(" ")[0])+" ")]}},{key:"item.shinseiType",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.shinseiType === 1 ? "間違い" : item.shinseiType === 2 ? "忘れ" : item.shinseiType === 3 ? "削除要請" : null)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.initselectedShinsei(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" 詳細 ")],1)]}}],null,false,2926028273),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{key:'dialogKey'+_vm.dialogKey,attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.display.selectedShinsei),callback:function ($$v) {_vm.$set(_vm.display, "selectedShinsei", $$v)},expression:"display.selectedShinsei"}},[_c('v-sheet',{attrs:{"height":"100%"}},[(_vm.selectedShinsei)?_c('shinsei-compare-form',{attrs:{"selectedShinsei":_vm.selectedShinsei,"singleMode":_vm.selectedShinsei.shinseiType === 3 ? true : false},on:{"close":function($event){_vm.display.selectedShinsei = false},"refresh":_vm.reloadData}}):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }