<template>
    <div 
        v-if="shinseiData"
        :key="'tableKey'+tableKey"
    >
        <div class="banner pa-5 text-center text-h3 rounded my-3">申請一覧</div>
        <v-data-table
            height="90vh"
            v-model="selected"
            :headers="headers"
            :items="shinseiData"
            no-data-text="データーがありません"
            item-key="_id"
            :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': '表示数',
                'items-per-page-options': [10, 50, -1],
            }"
            show-select
        >
            <template v-slot:item.created="{item}">
                {{item.created.split(" ")[0]}}
            </template>
            <template v-slot:item.shinseiType="{item}">
                <v-chip color="primary">
                    {{item.shinseiType === 1 ? "間違い" : item.shinseiType === 2 ? "忘れ" : item.shinseiType === 3 ? "削除要請" : null}}
                </v-chip>
            </template>
            <template v-slot:item.actions="{item}">
                <v-btn @click="initselectedShinsei(item)" color="primary">
                    <v-icon>mdi-magnify</v-icon>
                    詳細
                </v-btn>
            </template>
        </v-data-table>
        <!--Dialog -->
        <v-dialog persistent fullscreen v-model="display.selectedShinsei" :key="'dialogKey'+dialogKey">
            <v-sheet height="100%">
                <shinsei-compare-form
                    v-if="selectedShinsei"
                    :selectedShinsei="selectedShinsei" 
                    @close="display.selectedShinsei = false"
                    @refresh="reloadData"
                    :singleMode="selectedShinsei.shinseiType === 3 ? true : false"
                />
            </v-sheet>
        </v-dialog>
    </div>
</template>

<script>
import ShinseiCompareForm from '@/components/Shinsei/ShinseiCompareForm.vue'
export default {
    components:{
        ShinseiCompareForm
    },  
    data: () => ({
        tableKey:0,
        dialogKey:0,
        display:{
            selectedShinsei:false
        },
        shinseiData: null,
        selectedShinsei:null,
        selected: [],
        headers: [
            {
                text: "申請ID",
                align: "start",
                value: "_id",
            },
            { text: "申請日", value: "created" },
            { text: "区分", value: "shinseiType" },
            { text: "名前", value: "userId.name" },
            { text: "操作", value: "actions" },
        ],
    }),
    mounted() {
        this.getShinseiData();
    },
    methods: {
        reloadData(){
            this.display.selectedShinsei = false
            this.getShinseiData()
            this.tableKey++
        },
        initselectedShinsei(selectedShinsei){
            this.dialogKey++
            this.selectedShinsei = selectedShinsei
            this.display.selectedShinsei = true
        },
        async getShinseiData(uid = null) {
            let response = await this.$store.dispatch("get", {
                url: "/api/getShinsei?" + (uid ? "uid=" + uid : ""),
                showUI: true,
            });
            if (response.result) this.shinseiData = response.result;
        },
    },
};
</script>

<style>
</style>